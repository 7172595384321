<template>
  <div>
    <window-header></window-header>
    <nav style="justify-content: space-between">
      <button
        class="button button-fill"
        :class="{ disabled: tabDisabled(activeTab - 1) }"
        @click="$refs['main-tabs'].prevTab()"
      >
        <fa-icon :icon="['fas', 'chevron-left']" />
        <span>{{ $t("back") }}</span>
      </button>
      <button
        class="button button-fill"
        :class="{ disabled: tabDisabled(activeTab + 1) }"
        @click="$refs['main-tabs'].nextTab()"
        v-if="activeTab < 2"
      >
        <span>{{ $t("next") }}</span>
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
      <button
        v-if="activeTab == 2"
        :class="{ disabled: !lineasSelected().length }"
        class="button button-fill"
        @click="finalizar"
      >
        <span>Finalizar</span>
      </button>
      <template v-if="activeTab == 2">
        <field
          name="total_lineas"
          widget="float"
          readonly
          dec="2"
          label="Total"
          width="100px"
          style="top: 35px; left: 170px"
        />
        <field
          name="total_unidades"
          widget="float"
          readonly
          dec="2"
          label="Unidades"
          width="100px"
          style="top: 35px; left: 280px"
        />
        <field
          name="total_peso"
          widget="float"
          readonly
          dec="2"
          label="Peso"
          width="100px"
          style="top: 35px; left: 390px"
        />
      </template>
    </nav>
    <section>
      <article style="height: 436px; width: 675px">
        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top: 0px; left: 0px; height: 435px"
          :buttons="['1. Filtrar', '2. Condicionales', '3. Líneas']"
          :classButton="(i) => ({ disabled: tabDisabled(i) })"
          :styleButton="'width:33%;'"
          @active="onActiveTab"
        >
          <div class="tab">
            <fieldset
              style="
                position: relative;
                left: 80px;
                top: 80px;
                width: 480px;
                height: 200px;
              "
              model="albaran_proveedor"
            >
              <legend>Seleccione filtros</legend>
              <field
                name="proveedor_id"
                label="Proveedor"
                widget="m2o"
                labelProp="nombre_comercial"
                width="320px"
                style="top: 47px; left: 70px"
              />
              <field
                name="condicionales"
                widget="checkbox"
                label="Condicionales"
                reverse
                inline-label
                style="top: 90px; left: 70px"
              />

              <field
                name="notas_condicionales"
                widget="checkbox"
                label="Prop. de Condicionales"
                reverse
                inline-label
                style="top: 110px; left: 70px"
                v-if="showWt"
              />
              <field
                name="desde"
                type="date"
                label="Desde"
                width="100px"
                inline-label
                style="top: 106px; right: 50px"
              />
              <field
                name="hasta"
                type="date"
                label="Hasta"
                width="100px"
                inline-label
                style="top: 126px; right: 50px"
              />
            </fieldset>
          </div>
          <div class="tab">
            <field
              ref="documentos_pendientes"
              name="documentos_pendientes"
              widget="handsontable"
              :height="360"
              :width="650"
              :minRows="18"
              style="top: 30px; left: 4px; width: 653px"
              customprop="docs_customprop"
              :columns="[
                {
                  name: 'sel',
                  header: 'Sel.',
                  widget: 'checkbox',
                  help: 'Seleccionado',
                },
                { name: 'ndoc', header: 'NºDocumento', readOnly: true },
                {
                  name: 'proveedor_id',
                  header: 'Proveedor',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre_comercial',
                  showCode: true,
                },
                {
                  name: 'fecha',
                  type: 'date2',
                  header: 'Fecha',
                  readOnly: true,
                },
                {
                  name: 'total',
                  header: 'Total',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                {
                  name: 'cambio',
                  header: 'Cambio',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]"
            />
            <span style="position: absolute; top: 5px; left: 5px">
              <button
                name="sel_all_docs"
                v-tooltip="'Seleccionar todos'"
                @click="sellAllDocs"
              >
                <fa-icon :icon="['far', 'check-square']" />
              </button>
              <button
                name="dessel_all_docs"
                v-tooltip="'Desseleccionar todos'"
                @click="desselAllDocs"
              >
                <fa-icon :icon="['far', 'square']" />
              </button>
            </span>
          </div>
          <div class="tab">
            <field
              ref="lineas_pendientes"
              name="lineas_pendientes"
              widget="handsontable"
              label="Líneas"
              :height="380"
              :width="650"
              :minRows="18"
              style="top: 5px; left: 4px; width: 653px"
              customprop="lines_customprop"
              :htSettings="{ columnSorting: true }"
              :columns="[
                {
                  name: 'sel',
                  header: 'Sel.',
                  widget: 'checkbox',
                  help: 'Seleccionado',
                },
                {
                  name: 'ean13',
                  header: '|||||||||',
                  help: 'Código de barras del artículo',
                  readOnly: true,
                },
                {
                  name: 'articulo_id',
                  header: 'Artículo',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  noOpen: true,
                  fields: [
                    { name: 'thumbnail', fields: ['imagen'] },
                    'tarifa1',
                    'tarifa2',
                    'tarifa3',
                    'tarifa4',
                    'tarifa5',
                    'tarifa6',
                    'tarifa7',
                    'tarifa8',
                    'tarifa9',
                    'tarifa10',
                  ],
                  readOnly: true,
                },
                { name: 'descripcion', header: 'Descripción', readOnly: true },
                {
                  name: 'modelo_id',
                  header: 'Modelo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'refproveedor',
                  header: 'R. Proveedor',
                  readOnly: true,
                },
                { name: 'nota', header: 'Nota', readOnly: true },
                {
                  name: 'almacen_id',
                  header: 'Almacén',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'unidades_pendientes',
                  header: 'Unid. pendientes',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso_pendiente',
                  header: 'Peso pendiente',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'c',
                  header: 'C',
                  readOnly: true,
                  type: 'select',
                  options: ['U', 'P'],
                },
                { name: 'm', header: 'M', readOnly: true, type: 'checkbox' },
                { name: 'r', header: 'R', readOnly: true, type: 'checkbox' },

                {
                  name: 'precio',
                  header: 'Precio',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'totallinea',
                  header: 'Total Línea',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                { name: 'm2', header: 'M', readOnly: true, type: 'checkbox' },
                { name: 'c2', header: 'C', readOnly: true, type: 'checkbox' },
                { name: 'o', header: 'O', readOnly: true, type: 'checkbox' },
                {
                  name: 'precio_coste',
                  header: 'P. Coste',
                  readOnly: true,
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'coste_fijo',
                  header: 'Coste Fijo',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'taqueria_ids',
                  header: 'Taquería',
                  readOnly: true,
                  type: 'm2m',
                  primary: 'codigo',
                  image: 'thumbnail.imagen',
                  label: 'codigo',
                  fields: ['precio_coste'],
                  filter: () => ['seccion_id', '=', 'PCK'],
                },
                {
                  name: 'taqueria_precio',
                  header: 'Taq.(€)',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'incluir_taqueria',
                  header: 'P',
                  readOnly: true,
                  type: 'checkbox',
                },
                {
                  name: 'otros_costes',
                  header: 'O. Costes',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'v',
                  header: 'V',
                  readOnly: true,
                  type: 'select',
                  options: ['U', 'P'],
                },

                { name: 'm3', header: 'M', readOnly: true, type: 'checkbox' },
                {
                  name: 'margen',
                  header: '%',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'pventa',
                  header: 'PVP',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 't',
                  header: 'T',
                  readOnly: true,
                  type: 'select',
                  options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                },
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";
var floatOrZero = function (val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function () {
    return {
      title: "Generar devolución de Condicional de Compra",
      dbAdapter: "albaran_proveedor",
      primary: "ndoc",
      mode: "new",
      showWt: false,
      original_documentos_pendientes: [],
      original_lineas_pendientes: [],
      activeTab: 0,
      /*sequence: { name: "almacen" }*/
    };
  },
  methods: {
    finalizar() {
      var self = this;
      var initial_data = {
        proveedor_id: self.formData.proveedor_id,
      };
      var lineas_sel = [];
      self.formData.lineas_pendientes.forEach(function (o, i) {
        if (parseInt(o.sel)) {
          let oo = self.original_lineas_pendientes[i];
          oo.unidades = o.unidades;
          oo.etiqueta = o.unidades;
          oo.peso = o.peso;
          delete oo.unidades_facturadas;
          delete oo.peso_facturado;
          delete oo.unidades_devueltas;
          delete oo.peso_devuelto;
          delete oo.peso_pendiente;
          delete oo.unidades_pendientes;
          lineas_sel.push(oo);
        }
      });
      var la = [];
      var lb = [];
      lineas_sel.forEach(function (item, i) {
        let wt = parseInt(item.albaran_id.wt);
        delete item.albaran_id;
        if (wt) {
          lb.push(item);
        } else {
          la.push(item);
        }
      });
      var initial_data_b = window.$.extend(true, {}, initial_data);
      var initial_data_a = window.$.extend(true, {}, initial_data);
      if (la.length) {
        initial_data_a["lineas"] = la;
        self.app.openWindow("devolucion_condicional_compra", {
          props: {
            initialFormData: initial_data_a,
            initialMode: "new",
          },
        });
      }
      if (lb.length) {
        initial_data_b["lineas"] = lb;
        initial_data_b["wt"] = 1;
        self.app.openWindow("devolucion_condicional_compra", {
          props: {
            initialFormData: initial_data_b,
            initialMode: "new",
          },
        });
      }
      self.$emit("close");
    },
    wtRenderer: function (instance, td, row, col, prop, value, cellProperties) {
      var self = this;
      if (!self.$refs.documentos_pendientes.field) return;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      var wt = h.getSourceData()[row]["wt"];
      if (parseInt(wt)) {
        td.style.color = "black!important";
        //window.$(td).addClass("plum");
        td.style.background = "antiquewhite";
      }
      Handsontable.renderers.TextRenderer.apply(this, arguments);
    },
    docsSelected() {
      var self = this;
      return self.formData.documentos_pendientes
        ? self.formData.documentos_pendientes.filter((doc) => parseInt(doc.sel))
        : [];
    },
    lineasSelected() {
      var self = this;
      return self.formData.lineas_pendientes
        ? self.formData.lineas_pendientes.filter((doc) => parseInt(doc.sel))
        : [];
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      switch (i) {
        case 1:
          var p = Promise.resolve();
          var tts = {
            condicionales: {
              model: "albaran_proveedor",
              wt: 0,
            },
            notas_condicionales: {
              model: "albaran_proveedor",
              wt: 1,
            },
          };
          self.$set(self.formData, "documentos_pendientes", []);
          self.original_documentos_pendientes = [];
          self.loading = true;
          for (let tt in tts) {
            if (self.formData[tt]) {
              p = p.then(
                (_) =>
                  new Promise(function (resolve, reject) {
                    let filter = [
                      ["wt", "=", tts[tt].wt],
                      ["condicional", "=", 1],
                    ];
                    if (self.formData.proveedor_id)
                      filter.push([
                        "proveedor_id",
                        "=",
                        self.formData.proveedor_id.codigo,
                      ]);
                    if (self.formData.desde)
                      filter.push(["fecha", ">=", self.formData.desde]);
                    if (self.formData.hasta)
                      filter.push(["fecha", "<=", self.formData.hasta]);
                    window.DB.getList(tts[tt].model, {
                      filter,
                      fields: [
                        "ndoc",
                        "wt",
                        { name: "proveedor_id", fields: ["nombre_comercial"] },
                        "fecha",
                        "total",
                        { name: "divisa_id", fields: ["nombre"] },
                        "cambio",
                        {
                          name: "lineas",
                          fields: [
                            { name: "albaran_id", fields: ["wt"] },
                            {
                              name: "articulo_id",
                              fields: [
                                "codigo",
                                "ean13",
                                {
                                  name: "modelo_id",
                                  fields: ["codigo", "nombre"],
                                },
                                {
                                  name: "familia_id",
                                  fields: ["codigo", "nombre"],
                                },
                                {
                                  name: "tipo_comision_id",
                                  fields: ["codigo", "descripcion"],
                                },
                                "nombre",
                                "codigo_proveedor",
                                "unidad_medida_compra",
                                "compra_metal_incluido",
                                "recargo_equivalencia",
                                "incluir_contraste_pcoste",
                                "incluir_ocostes_pcoste",
                                "precio_coste",
                                "unidad_medida_venta",
                                "venta_metal_incluido",
                                "peso_unitario_fino",
                                "margen_ultima_compra",
                                {
                                  name: "taqueria_ids",
                                  fields: [
                                    "codigo",
                                    "nombre",
                                    { name: "thumbnail", fields: ["imagen"] },
                                    "precio_coste",
                                  ],
                                },
                                "taqueria_precio",
                                { name: "thumbnail", fields: ["imagen"] },
                              ],
                            },
                            "ean13",
                            { name: "modelo_id", fields: ["codigo", "nombre"] },
                            "descripcion",
                            "refproveedor",
                            "mpl",
                            "c",
                            "m",
                            "r",
                            "m2",
                            "c2",
                            "o",
                            "precio_coste",
                            "coste_fijo",
                            "precio",
                            "v",
                            "m3",
                            "t",
                            "unidades",
                            "etiqueta",
                            "peso",
                            "margen",
                            "pventa",
                            {
                              name: "taqueria_ids",
                              fields: [
                                "codigo",
                                "nombre",
                                { name: "thumbnail", fields: ["imagen"] },
                                "precio_coste",
                              ],
                            },
                            "incluir_taqueria",
                            "taqueria_precio",
                            "otros_costes",
                            {
                              name: "almacen_id",
                              fields: ["codigo", "nombre"],
                            },
                            "descuentos",
                            "totallinea",
                            "unidades_devueltas",
                            "peso_devuelto",
                            "unidades_facturadas",
                            "peso_facturado",
                          ],
                        },
                      ],
                    })
                      .then(function (res) {
                        // Filtramos los documentos que tengan líneas con unidades pendientes
                        res.data = res.data.filter((doc) =>
                          doc.lineas.some(
                            (linea) =>
                              parseInt(linea.unidades || 0) -
                              parseInt(linea.unidades_devueltas || 0) -
                              parseInt(linea.unidades_facturadas || 0)
                          )
                        );
                        res.data.forEach(function (doc) {
                          self.original_documentos_pendientes.push(
                            JSON.parse(JSON.stringify(doc))
                          );
                          delete doc.lineas;
                          self.formData.documentos_pendientes.push(doc);
                        });
                        resolve();
                      })
                      .catch(function () {
                        reject();
                      });
                  })
              );
            }
          }
          p.then(function () {
            if (!self.formData.documentos_pendientes.length) {
              self.app.toast("No hay condicionales a devolver", "error");
            }
            self.$forceUpdate();
          })
            .catch(function () {
              window.console.log("eeeeeer");
            })
            .finally(function () {
              self.loading = false;
            });
          break;
        case 2:
          self.$set(self.formData, "lineas_pendientes", []);
          self.original_lineas_pendientes = [];
          self.formData.documentos_pendientes.forEach(function (doc, i) {
            if (!parseInt(doc.sel)) return;
            //TODO: origin
            self.original_documentos_pendientes[i].lineas.forEach(function (
              linea
            ) {
              linea.unidades_pendientes =
                parseInt(linea.unidades || 0) -
                parseInt(linea.unidades_devueltas || 0) -
                parseInt(linea.unidades_facturadas || 0);
              linea.peso_pendiente =
                parseInt(linea.peso || 0) -
                parseInt(linea.peso_devuelto || 0) -
                parseInt(linea.peso_facturado || 0);
              if (!linea.unidades_pendientes && !linea.peso_pendiente) return;
              linea.unidades = linea.unidades_pendientes;
              linea.peso = linea.peso_pendiente;
              self.formData.lineas_pendientes.push(linea);
              let linea2 = JSON.parse(JSON.stringify(linea));
              linea2.origin_albaran_id = linea2.id;
              delete linea2.id;
              delete linea2.nota;
              self.original_lineas_pendientes.push(linea2);
            });
          });
          self.$forceUpdate();
      }
    },
    sellAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function (i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 1, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    desselAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function (i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 0, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    sellAllLines() {
      var self = this;
      var h = self.$refs.lineas_pendientes.field.hotInstance;
      h.getSourceData().forEach(
        (r) => (
          (r.sel = 1),
          (r.unidades = r.unidades_pendientes),
          (r.peso = r.peso_pendiente)
        )
      );
      h.render();
      self.actualizarTotales();
    },
    desselAllLines() {
      var self = this;
      var h = self.$refs.lineas_pendientes?.field.hotInstance;
      if (!h) return;
      h.getSourceData().forEach(
        (r) => ((r.sel = 0), (r.unidades = 0), (r.peso = 0))
      );
      h.render();
      self.actualizarTotales();
    },
    tabDisabled(i) {
      var self = this;
      switch (i) {
        case 0:
          return false;
        case 1:
          return (
            !self.formData.proveedor_id ||
            (!self.formData.condicionales && !self.formData.notas_condicionales)
          );
        case 2:
          return !self.docsSelected().length;
        default:
          return true;
      }
    },
    onChange(arrProps, callback) {
      this.$on("change", function (prop, source) {
        if (this.mode != "new" && this.mode != "edit") return;
        if (!arrProps.includes(prop)) return;
        callback(source);
      });
    },
    actualizarTotales() {
      var self = this;
      var total_lineas = 0;
      var total_unidades = 0;
      var total_peso = 0;
      var h = self.$refs.lineas_pendientes.field.hotInstance;
      h.getSourceData().forEach((r) => {
        if (parseInt(r.sel || 0)) {
          total_lineas +=
            parseFloat(r.unidades || 0) * parseFloat(r.precio || 0); //TODO: compra a peso?
          total_unidades += parseFloat(r.unidades || 0);
          total_peso += parseFloat(r.peso || 0);
        }
      });
      self.formData.total_lineas = total_lineas.toFixed(2);
      self.formData.total_unidades = total_unidades.toFixed(2);
      self.formData.total_peso = total_peso.toFixed(2);
      self.$forceUpdate();
    },
  },
  mounted() {
    var self = this;
    self.$refs["main-tabs"].$refs.toolbar.addEventListener(
      "dblclick",
      function (e) {
        if (self.app.session.mode == "a") return;
        if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
          if (
            self.mode != "edit" &&
            self.mode != "new" &&
            self.mode != "search"
          )
            return;
          self.showWt = true;
          self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
          self.$emit("change", "wt", "edit");
          if (self.mode == "search") self.setFilter(self.getFilter());
        }
      }
    );

    self.$refs.lineas_pendientes.field.hotInstance.addHook(
      "afterChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          if (prop == "sel") {
            self.actualizarTotales();
          }
        });
      }
    );

    self.$refs.lineas_pendientes.field.hotInstance.addHook(
      "beforeChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          if (prop == "sel") {
            changes.push([
              row,
              "unidades",
              null,
              newVal ? h.getSourceData()[row].unidades_pendientes : 0,
            ]);
            changes.push([
              row,
              "peso",
              null,
              newVal ? h.getSourceData()[row].peso_pendiente : 0,
            ]);
          }
          if (prop == "unidades") {
            let unidades = parseFloat(newVal || 0);
            let unidades_pendientes = parseFloat(
              h.getSourceData()[row].unidades_pendientes || 0
            );
            if (unidades > unidades_pendientes) {
              changes[index][3] = unidades_pendientes;
            } else if (unidades < 0) {
              changes[index][3] = unidades_pendientes;
            }
            if (unidades) {
              changes.push([row, "sel", null, 1]);
            } else {
              changes.push([row, "sel", null, 0]);
            }
            changes.push([
              row,
              "peso",
              null,
              h.getSourceData()[row].peso_pendiente *
                (unidades / unidades_pendientes),
            ]);
          }
          if (prop == "peso") {
            let peso = parseFloat(newVal || 0);
            let peso_pendiente = parseFloat(
              h.getSourceData()[row].peso_pendiente || 0
            );
            if (peso > peso_pendiente) {
              changes[index][3] = peso_pendiente;
            } else if (peso < 0) {
              changes[index][3] = peso_pendiente;
            }
            if (peso) {
              changes.push([row, "sel", null, 1]);
            } else {
              changes.push([row, "sel", null, 0]);
            }
          }
        });
      }
    );
  },
};
</script>
<style>
tr.wtdoc > td {
  background: #72dad7 !important;
  color: white !important;
}
</style>